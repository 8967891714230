import React from 'react'
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import Roll from 'react-reveal/Roll';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import ReactCompareImage from 'react-compare-image';
import ReactPlayer from "react-player";

const parallax = {
    backgroundImage: 'url(../recaGhv4gNNk3RmZz.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

const paraOne = {
    backgroundImage: 'url(../recPTtSKaaMEAtWcy.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTwo = {
    backgroundImage: 'url(../rec106Xo96tUHNbAQ.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraThree = {
    backgroundImage: 'url(../recIP2nO8piym1d9I.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFour = {
    backgroundImage: 'url(../recSovp5BdPO5FQBz.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFive = {
    backgroundImage: 'url(../rectjbhFDvJgv2kz6.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSix = {
    backgroundImage: 'url(../rec2tMf0BYmaHScBL.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSeven = {
    backgroundImage: 'url(../recPzrw7g0qeZq9Ji.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraEight = {
    backgroundImage: 'url(../rec1eEL1rfJ5AWJcR.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 184, 29, 0.45), rgba(255, 184, 29, 0.45)), url(../recpB44hHmI8O2uFh.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/recaGhv4gNNk3RmZz.jpg" />
            <meta property="og:image:width" content="1800" />
            <meta property="og:image:height" content="1350" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/recaGhv4gNNk3RmZz.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.meta.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="lanterns"></div>
            <div className="container mt-5 story">
            {/* intro */}
            <section>
            <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
            __html: data.intro.data.text.childMarkdownRemark.html,
            }} />
            </section>
            <section className="mt-5">
            <Pulse>
            <Img fluid={data.rec37H7qKjB39fnLi.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec37H7qKjB39fnLi.data.alt} />
            </Pulse>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.rec5DhnB1RhLC1Kp2.data.text.childMarkdownRemark.html,
            }} className="my-5" />
            </Fade>
            <Fade bottom>
            <Img fluid={data.recnjldiw4stlHU67.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnjldiw4stlHU67.data.alt} />
            </Fade>
            <div className="row mt-5">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recRmQl3ViVPKxeyt.data.file.localFiles[0].childImageSharp.fixed} alt={data.recRmQl3ViVPKxeyt.data.alt} className="w-100" />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recnueyVQhq1u46Gq.data.file.localFiles[0].childImageSharp.fixed} alt={data.recnueyVQhq1u46Gq.data.alt} className="w-100" />
                </div>
                </Fade>
            </div>
            </section>
            </div>
            <section>
            <div style={paraOne} role="img" aria-label={data.recPTtSKaaMEAtWcy.data.alt} className="parallax mt-5"></div>
            </section>
            <div className="container story">
            <section>
            <div className="mt-2 mb-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
            __html: data.recPTtSKaaMEAtWcy.data.text.childMarkdownRemark.html,
            }} />
            <div className="row my-5">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recuvkcqFbxub75kw.data.file.localFiles[0].childImageSharp.fixed} alt={data.recuvkcqFbxub75kw.data.alt} className="w-100" />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recrEDt7FKFScnA1C.data.file.localFiles[0].childImageSharp.fixed} alt={data.recrEDt7FKFScnA1C.data.alt} className="w-100" />
                </div>
                </Fade>
            </div>
            <Fade bottom>
            <Img fluid={data.recyZkExAYfH0dt12.data.file.localFiles[0].childImageSharp.fluid} alt={data.recyZkExAYfH0dt12.data.alt} className="mb-3" />
            <Img fluid={data.recmeASBLpGcPnCkf.data.file.localFiles[0].childImageSharp.fluid} alt={data.recmeASBLpGcPnCkf.data.alt} />
            </Fade>
            <div className="row my-5">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recaVc2Kz4YEHgRXG.data.file.localFiles[0].childImageSharp.fixed} alt={data.recaVc2Kz4YEHgRXG.data.alt} className="w-100" />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recZ2MsHUaOPpNsG9.data.file.localFiles[0].childImageSharp.fixed} alt={data.recZ2MsHUaOPpNsG9.data.alt} className="w-100" />
                </div>
                </Fade>
            </div>
            <Fade bottom>
            <Img fluid={data.recCt5nZjKen8MZQK.data.file.localFiles[0].childImageSharp.fluid} alt={data.recCt5nZjKen8MZQK.data.alt} className="mb-3" />
            <Img fluid={data.recGaZ4Ve2qkOsTAv.data.file.localFiles[0].childImageSharp.fluid} alt={data.recGaZ4Ve2qkOsTAv.data.alt} />
            </Fade>
            <div className="row my-5">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recQGSzqH1mvBcAuu.data.file.localFiles[0].childImageSharp.fixed} alt={data.recQGSzqH1mvBcAuu.data.alt} className="w-100" />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recuvg0GtA30bptEf.data.file.localFiles[0].childImageSharp.fixed} alt={data.recuvg0GtA30bptEf.data.alt} className="w-100" />
                </div>
                </Fade>
            </div>
            </section>
            </div>
            <section>
            <div style={paraTwo} role="img" aria-label={data.rec106Xo96tUHNbAQ.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.recSNBfBZ18FjsX3n.data.text.childMarkdownRemark.html,
            }} className="mb-5" />
            </Fade>
            <Roll>
            <Img fluid={data.reckNnrpARNLbYwES.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckNnrpARNLbYwES.data.alt} className="mb-3" />
            <Img fluid={data.recJ3QDWkiRly1Wk9.data.file.localFiles[0].childImageSharp.fluid} alt={data.recJ3QDWkiRly1Wk9.data.alt} className="mb-3" />
            </Roll>
            <Fade bottom>
            <Img fluid={data.recJwFtQXqfLSvPj5.data.file.localFiles[0].childImageSharp.fluid} alt={data.recJwFtQXqfLSvPj5.data.alt} />
            </Fade>
            </section>
            </div>
            <section>
            <div style={paraThree} role="img" aria-label={data.recIP2nO8piym1d9I.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade bottom>
            <Img fluid={data.rec1O8lTR3XwkMaUT.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1O8lTR3XwkMaUT.data.alt} />
            </Fade>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.recb2iB0MwAcH4NHI.data.text.childMarkdownRemark.html,
            }} className="my-5" />
            </Fade>
            </section>
            </div>
            <section>
            <div style={paraFour} role="img" aria-label={data.recSovp5BdPO5FQBz.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade>
            <div className="col-lg-10 mx-auto">
            <ReactCompareImage
                leftImage="https://dl.airtable.com/.attachmentThumbnails/8e1450cf475e752f23336fee4383cad0/a1a725cd"
                leftImageAlt="A person hands another person a piece of pie and ice cream on a plate."
                rightImage="https://dl.airtable.com/.attachmentThumbnails/eed4897fde3511580ff4c1f02731b744/10e66f2c"
                rightImageAlt="Popcorn pops in a popcorn maker."
                sliderPositionPercentage="0.5"
                aspectRatio="wider"
            />
            </div>
            </Fade>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.receHAmar0lrilEUc.data.text.childMarkdownRemark.html,
            }} className="my-5" />
            </Fade>
            <Fade bottom>
            <Img fluid={data.recGB4WzhjkYWptmJ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recGB4WzhjkYWptmJ.data.alt} />
            </Fade>
            <Fade>
            <div className="row my-5">
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.recOlTjDN40KxQ3fF.data.file.localFiles[0].childImageSharp.fixed} alt={data.recOlTjDN40KxQ3fF.data.alt} className="w-100" />
                </div>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fixed={data.rec6iO48uBWQmQOLd.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec6iO48uBWQmQOLd.data.alt} className="w-100" />
                </div>
            </div>
            </Fade>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.rec0pnUW7wAk5eJV6.data.text.childMarkdownRemark.html,
            }} className="my-5" />
            </Fade>
            </section>
            </div>
            <section>
            <div style={paraFive} role="img" aria-label={data.rectjbhFDvJgv2kz6.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade>
            <div className="row">
            {data.recdb0ISnQffjnDRF.nodes.map(node => (
                <div className="col-md-4 col-8 mb-3 mx-auto" key={node.recordId}>
                    <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100" />
                </div>
            ))}
            </div>
            </Fade>
            <Fade bottom>
            <Img fluid={data.recQAdz3mubMhqsmo.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQAdz3mubMhqsmo.data.alt} className="mb-3" />
            <Img fluid={data.recQcnK838NnODW72.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQcnK838NnODW72.data.alt} className="mb-3" />
            <Img fluid={data.recQhOig1mJW7uYOH.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQhOig1mJW7uYOH.data.alt} className="mb-3" />
            <Img fluid={data.rec1A0Jq0TsnvfgWv.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1A0Jq0TsnvfgWv.data.alt} className="mb-3" />
            <Img fluid={data.recK1blEfgvhuOesZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recK1blEfgvhuOesZ.data.alt} />
            </Fade>
            <div dangerouslySetInnerHTML={{
            __html: data.recsQckGfn5kOzUdW.data.text.childMarkdownRemark.html,
            }} className="my-5" />
            </section>
            </div>
            <section>
            <div style={paraSix} role="img" aria-label={data.rec2tMf0BYmaHScBL.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Pulse>
            <Img fluid={data.recYYjq4wpzwDyIFW.data.file.localFiles[0].childImageSharp.fluid} alt={data.recYYjq4wpzwDyIFW.data.alt} className="mb-3" />
            <Img fluid={data.recAKzYkOJQsTEjU9.data.file.localFiles[0].childImageSharp.fluid} alt={data.recAKzYkOJQsTEjU9.data.alt} />
            </Pulse>
            <Zoom>
            <div className="row no-gutters my-5">
                {data.grid.nodes.map(node => (
                    <Fade key={node.recordId}>
                    <div className="col-lg-4 col-6">
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="m-1" />
                    </div>
                    </Fade>
                ))}
            </div>
            </Zoom>
            <Flip>
            <Img fluid={data.recOzCmrnc9rOSYWb.data.file.localFiles[0].childImageSharp.fluid} alt={data.recOzCmrnc9rOSYWb.data.alt} className="mb-3" />
            </Flip>
            <Pulse>
            <Img fluid={data.rec9syjXKKwtCgqKQ.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec9syjXKKwtCgqKQ.data.alt} className="mb-3" />
            </Pulse>
            <Zoom>
            <Img fluid={data.rec6sLLGdZytHjOEc.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec6sLLGdZytHjOEc.data.alt} className="mb-3" />
            </Zoom>
            <Fade bottom>
            <Img fluid={data.recvC2G4Q4HFmMAmZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recvC2G4Q4HFmMAmZ.data.alt} />
            </Fade>
            </section>
            </div>
            <section>
            <div style={paraSeven} role="img" aria-label={data.recPzrw7g0qeZq9Ji.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade bottom>
            <Img fluid={data.recsV6r32WENOusva.data.file.localFiles[0].childImageSharp.fluid} alt={data.recsV6r32WENOusva.data.alt} className="mb-3" />
            </Fade>
            <Pulse>
            <Img fluid={data.reciJ4hgdS3oPFyhC.data.file.localFiles[0].childImageSharp.fluid} alt={data.reciJ4hgdS3oPFyhC.data.alt} className="mb-3" />
            </Pulse>
            <Flip>
            <Img fluid={data.recs5dkaKGsE3Q2RR.data.file.localFiles[0].childImageSharp.fluid} alt={data.recs5dkaKGsE3Q2RR.data.alt} />
            </Flip>
            <Pulse>
            <div className="row my-5">
                <div className="col-md-4 col-8 mb-md-0 mb-3 mx-auto">
                <Img fluid={data.rec4JxFjRMLEML2eu.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec4JxFjRMLEML2eu.data.alt} />
                </div>
                <div className="col-md-4 col-8 mb-md-0 mb-3 mx-auto">
                <Img fluid={data.recjcXPikOQRl2CDF.data.file.localFiles[0].childImageSharp.fluid} alt={data.recjcXPikOQRl2CDF.data.alt} />
                </div>
            </div>
            </Pulse>
            <Zoom>
            <Img fluid={data.recdP1VDi7ygvu33H.data.file.localFiles[0].childImageSharp.fluid} alt={data.recdP1VDi7ygvu33H.data.alt} />
            </Zoom>
            </section>
            </div>
            <section>
            <div style={paraEight} role="img" aria-label={data.rec1eEL1rfJ5AWJcR.data.alt} className="parallax my-5"></div>
            </section>
            <div className="container story">
            <section>
            <Fade bottom>
            <div className="row">
            {data.recyFsRrtanRDm4yT.nodes.map(node => (
                <div className="col-lg-4 col-md-8 col-10 mb-md-0 mb-3 mx-auto" key={node.recordId}>
                    <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100 mb-5" />
                </div>
            ))}
            </div>
            </Fade>
            <Jump>
            <Img fluid={data.rec8uZyJJKA4TLkgu.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec8uZyJJKA4TLkgu.data.alt} className="mb-3" />
            <Img fluid={data.recWHj5Co6wQotzFk.data.file.localFiles[0].childImageSharp.fluid} alt={data.recWHj5Co6wQotzFk.data.alt} className="mb-3" />
            </Jump>
            <Tada>
            <Img fluid={data.recFjfJnnwzTiOOIY.data.file.localFiles[0].childImageSharp.fluid} alt={data.recFjfJnnwzTiOOIY.data.alt} />
            </Tada>
            <div className="mt-2 mb-3" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
            __html: data.recFjfJnnwzTiOOIY.data.text.childMarkdownRemark.html,
            }} />
            <Pulse>
            <Img fluid={data.recijygfETAmokjDh.data.file.localFiles[0].childImageSharp.fluid} alt={data.recijygfETAmokjDh.data.alt} className="mb-3" />
            </Pulse>
            <Tada bottom>
            <Img fluid={data.reckn1w7YZVDFzf0R.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckn1w7YZVDFzf0R.data.alt} />
            </Tada>
            <div className="my-5">
            <ReactPlayer
                url="https://vimeo.com/272573006"
                controls="true"
                width="100%"
                height="450px"
            />
            </div>
            <div dangerouslySetInnerHTML={{
            __html: data.rec8uH53ilJWQccYC.data.text.childMarkdownRemark.html,
            }} className="mb-5" />
            <div dangerouslySetInnerHTML={{
            __html: data.recUnOzfQQX3NK7kd.data.text.childMarkdownRemark.html,
            }} className="mb-4" />
            <div className="row">
                <div className="col-lg-4 col-8 mb-lg-0 mb-3 mx-auto">
                    <Img fluid={data.recYIQ5USk5Vw17JN.data.file.localFiles[0].childImageSharp.fluid} alt={data.recYIQ5USk5Vw17JN.data.alt} />
                </div>
                <div className="col-lg-8 story" style={{display:'flex', alignItems:'center'}}>
                    <div dangerouslySetInnerHTML={{
                    __html: data.recYIQ5USk5Vw17JN.data.text.childMarkdownRemark.html,
                    }} />
                </div>
            </div>
            </section>
            </div>
            <section className="mt-5">
                <div style={credit} role="img" aria-label={data.recpB44hHmI8O2uFh.data.alt} className="py-5 mb-0 text-center">
                    <div className="credit-text container">
                        <p className="h3 credit-title">{data.recpB44hHmI8O2uFh.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recpB44hHmI8O2uFh.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
            
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "recaGhv4gNNk3RmZz"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

      lanterns: file(relativePath: {eq: "lanterns.png"}) {
        childImageSharp {
            fluid(maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    title: airtable(data: {identifier: {eq: "recegSFNF3XxXacMo"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "recLI6iSzvlmHLkyp"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec37H7qKjB39fnLi: airtable(data: {identifier: {eq: "rec37H7qKjB39fnLi"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec5DhnB1RhLC1Kp2: airtable(data: {identifier: {eq: "rec5DhnB1RhLC1Kp2"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnjldiw4stlHU67: airtable(data: {identifier: {eq: "recnjldiw4stlHU67"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recRmQl3ViVPKxeyt: airtable(recordId: {eq: "recRmQl3ViVPKxeyt"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recnueyVQhq1u46Gq: airtable(recordId: {eq: "recnueyVQhq1u46Gq"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER, height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recPTtSKaaMEAtWcy: airtable(recordId: {eq: "recPTtSKaaMEAtWcy"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recuvkcqFbxub75kw: airtable(recordId: {eq: "recuvkcqFbxub75kw"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recrEDt7FKFScnA1C: airtable(recordId: {eq: "recrEDt7FKFScnA1C"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER, height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recyZkExAYfH0dt12: airtable(data: {identifier: {eq: "recyZkExAYfH0dt12"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recmeASBLpGcPnCkf: airtable(data: {identifier: {eq: "recmeASBLpGcPnCkf"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recaVc2Kz4YEHgRXG: airtable(recordId: {eq: "recaVc2Kz4YEHgRXG"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recZ2MsHUaOPpNsG9: airtable(recordId: {eq: "recZ2MsHUaOPpNsG9"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER, height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recCt5nZjKen8MZQK: airtable(data: {identifier: {eq: "recCt5nZjKen8MZQK"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recGaZ4Ve2qkOsTAv: airtable(data: {identifier: {eq: "recGaZ4Ve2qkOsTAv"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recQGSzqH1mvBcAuu: airtable(recordId: {eq: "recQGSzqH1mvBcAuu"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recuvg0GtA30bptEf: airtable(recordId: {eq: "recuvg0GtA30bptEf"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER, height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    rec106Xo96tUHNbAQ: airtable(recordId: {eq: "rec106Xo96tUHNbAQ"}) {
        data {
            alt
        }
    }

    recSNBfBZ18FjsX3n: airtable(data: {identifier: {eq: "recSNBfBZ18FjsX3n"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reckNnrpARNLbYwES: airtable(data: {identifier: {eq: "reckNnrpARNLbYwES"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recJ3QDWkiRly1Wk9: airtable(data: {identifier: {eq: "recJ3QDWkiRly1Wk9"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recJwFtQXqfLSvPj5: airtable(data: {identifier: {eq: "recJwFtQXqfLSvPj5"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recIP2nO8piym1d9I: airtable(recordId: {eq: "recIP2nO8piym1d9I"}) {
        data {
            alt
        }
    }

    rec1O8lTR3XwkMaUT: airtable(data: {identifier: {eq: "rec1O8lTR3XwkMaUT"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recb2iB0MwAcH4NHI: airtable(data: {identifier: {eq: "recb2iB0MwAcH4NHI"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recSovp5BdPO5FQBz: airtable(recordId: {eq: "recSovp5BdPO5FQBz"}) {
        data {
            alt
        }
    }

    receHAmar0lrilEUc: airtable(data: {identifier: {eq: "receHAmar0lrilEUc"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recGB4WzhjkYWptmJ: airtable(data: {identifier: {eq: "recGB4WzhjkYWptmJ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recOlTjDN40KxQ3fF: airtable(recordId: {eq: "recOlTjDN40KxQ3fF"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    rec6iO48uBWQmQOLd: airtable(recordId: {eq: "rec6iO48uBWQmQOLd"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: CENTER, height: 350, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    rec0pnUW7wAk5eJV6: airtable(data: {identifier: {eq: "rec0pnUW7wAk5eJV6"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectjbhFDvJgv2kz6: airtable(recordId: {eq: "rectjbhFDvJgv2kz6"}) {
        data {
            alt
        }
    }

    recdb0ISnQffjnDRF: allAirtable(filter: {data: {group: {eq: "crw1"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: ATTENTION, height: 450, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recQAdz3mubMhqsmo: airtable(data: {identifier: {eq: "recQAdz3mubMhqsmo"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recQcnK838NnODW72: airtable(data: {identifier: {eq: "recQcnK838NnODW72"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recQhOig1mJW7uYOH: airtable(data: {identifier: {eq: "recQhOig1mJW7uYOH"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec1A0Jq0TsnvfgWv: airtable(data: {identifier: {eq: "rec1A0Jq0TsnvfgWv"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recK1blEfgvhuOesZ: airtable(data: {identifier: {eq: "recK1blEfgvhuOesZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recsQckGfn5kOzUdW: airtable(data: {identifier: {eq: "recsQckGfn5kOzUdW"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec2tMf0BYmaHScBL: airtable(recordId: {eq: "rec2tMf0BYmaHScBL"}) {
        data {
            alt
        }
    }

    recYYjq4wpzwDyIFW: airtable(data: {identifier: {eq: "recYYjq4wpzwDyIFW"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recAKzYkOJQsTEjU9: airtable(data: {identifier: {eq: "recAKzYkOJQsTEjU9"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    grid: allAirtable(filter: {data: {group: {eq: "crw2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 370, maxWidth: 370) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recOzCmrnc9rOSYWb: airtable(data: {identifier: {eq: "recOzCmrnc9rOSYWb"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec9syjXKKwtCgqKQ: airtable(data: {identifier: {eq: "rec9syjXKKwtCgqKQ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec6sLLGdZytHjOEc: airtable(data: {identifier: {eq: "rec6sLLGdZytHjOEc"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recvC2G4Q4HFmMAmZ: airtable(data: {identifier: {eq: "recvC2G4Q4HFmMAmZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recPzrw7g0qeZq9Ji: airtable(recordId: {eq: "recPzrw7g0qeZq9Ji"}) {
        data {
            alt
        }
    }

    recsV6r32WENOusva: airtable(data: {identifier: {eq: "recsV6r32WENOusva"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reciJ4hgdS3oPFyhC: airtable(data: {identifier: {eq: "reciJ4hgdS3oPFyhC"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recs5dkaKGsE3Q2RR: airtable(data: {identifier: {eq: "recs5dkaKGsE3Q2RR"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec4JxFjRMLEML2eu: airtable(recordId: {eq: "rec4JxFjRMLEML2eu"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recjcXPikOQRl2CDF: airtable(recordId: {eq: "recjcXPikOQRl2CDF"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recdP1VDi7ygvu33H: airtable(data: {identifier: {eq: "recdP1VDi7ygvu33H"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec1eEL1rfJ5AWJcR: airtable(recordId: {eq: "rec1eEL1rfJ5AWJcR"}) {
        data {
            alt
        }
    }

    recyFsRrtanRDm4yT: allAirtable(filter: {data: {group: {eq: "crw3"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: CENTER, height: 300, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec8uZyJJKA4TLkgu: airtable(data: {identifier: {eq: "rec8uZyJJKA4TLkgu"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recWHj5Co6wQotzFk: airtable(data: {identifier: {eq: "recWHj5Co6wQotzFk"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recFjfJnnwzTiOOIY: airtable(data: {identifier: {eq: "recFjfJnnwzTiOOIY"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recijygfETAmokjDh: airtable(data: {identifier: {eq: "recijygfETAmokjDh"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reckn1w7YZVDFzf0R: airtable(data: {identifier: {eq: "reckn1w7YZVDFzf0R"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec8uH53ilJWQccYC: airtable(data: {identifier: {eq: "rec8uH53ilJWQccYC"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recUnOzfQQX3NK7kd: airtable(data: {identifier: {eq: "recUnOzfQQX3NK7kd"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recYIQ5USk5Vw17JN: airtable(recordId: {eq: "recYIQ5USk5Vw17JN"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recpB44hHmI8O2uFh: airtable(recordId: {eq: "recpB44hHmI8O2uFh"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

}  
`;